import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "../components/Common/Loader";
import AuthRoutes from "./Auth/AuthRoutes";
import Landing from "./Auth/Landing";
import {
  AuthenticatedRoute,
  UnAuthenticatedRoute,
} from "../components/Common/RouteComponents";
const StudyRoutes = React.lazy(() => import("./Study/studyRoutes"));
const StepTwoFailure = React.lazy(() => import("./Eligibility/StepTwoFailure"));
const EligibilityLayout = React.lazy(
  () => import("./Eligibility/EligibilityLayout")
);
const ParticipantRoutes = React.lazy(
  () => import("./ParticipantScreens/ParticipantRoutes")
);
const MyStudies = React.lazy(() => import("./Mystudies/MyStudies"));

function AllRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route
          path="/my-studies"
          element={
            <AuthenticatedRoute disallowedRoles={["patient"]}>
              <MyStudies />
            </AuthenticatedRoute>
          }
        />
        <Route path="/study/*" element={<StudyRoutes />} />
        <Route
          path="/eligible/:id"
          element={
            <UnAuthenticatedRoute>
              <EligibilityLayout />
            </UnAuthenticatedRoute>
          }
        />
        <Route
          path="/eligible/failure"
          element={
            <UnAuthenticatedRoute>
              <StepTwoFailure />
            </UnAuthenticatedRoute>
          }
        />
        <Route path="/participant/*" element={<ParticipantRoutes />} />
        <Route path="/" element={<Landing />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </React.Suspense>
  );
}

export default AllRoutes;
