import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import modalReducer from "./reducers/modalSlice";
import userReducer from "./reducers/userSlice";
import appointmentReducer from "./reducers/appointmentSlice";
import administratorSlice from "./reducers/administratorSlice";
import participantSlice from "./reducers/participantSlice";
import loaderSlice from "./reducers/loaderSlice";
import questionReducer from "./reducers/questionsReducer";
import documentsSlice from "./reducers/documentsSlice";
import diarySlice from "./reducers/diarySlice";
import dashboardSlice from "./reducers/dashboardSlice";
import eligibilitySlice from "./reducers/eligibilitySlice";

export const store = configureStore({
  reducer: {
    loader: loaderSlice,
    modal: modalReducer,
    user: userReducer,
    administrator: administratorSlice,
    participant: participantSlice,
    appointment: appointmentReducer,
    documents: documentsSlice,
    question: questionReducer,
    diary: diarySlice,
    dashboard: dashboardSlice,
    eligibility: eligibilitySlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
