import http from "../http";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { toastMessage, errorToastMessage } from "../helpers/toastMessage";
import { store } from "../redux/store";
import { setAppLoader } from "../redux/reducers/loaderSlice";

export const UploadImage =
  (fileObj: any, handleUpload: (data: any) => void, type: string) => () => {
    const file = fileObj.file;

    const options = {
      maxSizeMB: 5,
      useWebWorker: true,
    };

    let compressedFile: File;
    store.dispatch(setAppLoader(true));
    imageCompression(file, options).then((x) => {
      compressedFile = new File([x], file.name, { type: x.type });

      const imgBody = {
        assets: [
          {
            fileName: compressedFile.name,
            type,
          },
        ],
      };
      http
        .post("/assets/get_upload_urls", imgBody)
        .then((res) => {
          axios
            .put(`${res.data.data[0].presignedUploadUrl}`, compressedFile, {
              headers: { "Content-Type": res.data.data[0].mimeType },
            })
            .then(() => {
              store.dispatch(setAppLoader(false));
              toastMessage("success", "Image uploaded successfully");

              handleUpload(res.data.data);
            })
            .catch((err) => {
              store.dispatch(setAppLoader(false));
              errorToastMessage(err as Error);
            });
        })
        .catch((err) => {
          store.dispatch(setAppLoader(false));
          errorToastMessage(err as Error);
        });
    });
  };

export const uploadAsset = (file: File, type: string, msg: string) => {
  return async () => {
    const fileObj = {
      assets: [{ fileName: file.name, type }],
    };

    try {
      store.dispatch(setAppLoader(true));
      const res = await http.post("/assets/get_upload_urls", fileObj);
      const status = await axios.put(
        res.data.data[0].presignedUploadUrl,
        file,
        {
          headers: { "Content-Type": res.data.data[0].mimeType },
        }
      );
      store.dispatch(setAppLoader(false));
      if (status) {
        // toastMessage("success", msg);
        return res.data.data[0].postUploadImageUrl;
      } else {
        throw new Error("Attachment failed to upload!");
      }
    } catch (err) {
      store.dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };
};

export const UploadPdf =
  (fileObj: any, handleUpload: any, type: string) => () => {
    const pdfBody = {
      assets: [
        {
          fileName: fileObj.file.name,
          type,
        },
      ],
    };
    store.dispatch(setAppLoader(true));
    http
      .post("/assets/get_upload_urls", pdfBody)
      .then((res) => {
        axios
          .put(`${res.data.data[0].presignedUploadUrl}`, fileObj.file, {
            headers: { "Content-Type": fileObj.file.type },
          })
          .then((_) => {
            handleUpload(res.data.data);
            store.dispatch(setAppLoader(false));
          })
          .catch((err) => {
            store.dispatch(setAppLoader(false));
            errorToastMessage(err as Error);
          });
      })
      .catch((err) => {
        store.dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      });
  };
