import { Modal, Button, Select, Menu, Dropdown } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import React, { useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { MoreOutlined } from "@ant-design/icons";

const { Option } = Select;

type Props = {
  show: boolean;
  email: string;
};

const MyMenu = () => (
  <Menu>
    <Menu.Item>Resend Invite</Menu.Item>
  </Menu>
);

const ManageModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [listlength, setListLength] = useState(1);

  const submitHandler = async () => {
    try {
      // if (!remark.trim()) {
      //   toastMessage("warning", "Remark cannot be empty");
      //   return;
      // }
      // dispatch(setAppLoader(true));
      // const body = {
      //   phaseId: props.selectedQuestionniare?.phaseId,
      //   questionnaireId: props.selectedQuestionniare?.id,
      //   questionId: props.questionId,
      //   userId: props.userId,
      //   remark: remark,
      // };
      // let res = await http.post(`/queries`, body);
      // toastMessage("success", res.data.message);
      // closeHandler();
      // props.fetchQueries();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };
  return (
    <Modal
      title={"Manage User"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group mb-5">
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-secondary font-m fw-medium mb-1 text-uppercase">
                Email
              </div>
              <Dropdown overlay={MyMenu} trigger={["click"]}>
                <span className="cp" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined style={{ fontSize: "1.4rem" }} />
                </span>
              </Dropdown>
            </div>
            <p className="mt-2">{props.email}</p>
          </div>
          <div>
            <div className="row" style={{ marginBottom: "3vh" }}>
              <div className="text-secondary font-m fw-medium mb-1 text-uppercase col-md-6">
                Role
              </div>
              <div className="text-secondary font-m fw-medium mb-1 text-uppercase col-md-6">
                Site(s)
              </div>
            </div>
            {Array(listlength)
              .fill(1)
              .map((x, i) => (
                <React.Fragment key={i}>
                  <div className="row" style={{ marginBottom: "3vh" }}>
                    <div className="col-md-6">
                      <Select
                        size={"large"}
                        className="sd__modal-select"
                        placeholder="Please select..."
                        onChange={() => {}}
                      >
                        <Option value="1">Study Admin</Option>
                        <Option value="2">Site Admin</Option>
                        <Option value="3">Site Investigator</Option>
                        <Option value="4">Site Monitor</Option>
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <Select
                        size={"large"}
                        className="sd__modal-select"
                        placeholder="Please select..."
                        onChange={() => {}}
                      >
                        <Option value="1">Test site 1</Option>
                        <Option value="2">Test site 2</Option>
                        <Option value="3">Test site 3</Option>
                      </Select>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            <Button
              style={{ borderRadius: "6px", height: "40px" }}
              onClick={() => setListLength(listlength + 1)}
            >
              + Add Another
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default ManageModal;
