import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const buttonHandler = () => {
    navigate("/auth/login");
  };

  const eligibleHandler = () => {
    navigate("/eligible/1");
  };

  return (
    <div className="econsent-landing">
      <div className="landing-nav">
        <img src="/LargeLogo.svg" alt="logo" />
        <Button
          onClick={buttonHandler}
          style={{
            height: "40px",
            borderRadius: "6px",
          }}
          type="primary"
        >
          <span className="fw-semibold font-sm">Login</span>
        </Button>
      </div>
      <div className="econsent-landing-content">
        <div className="econsent-landing-wrapper">
          <img src="/LargeLogo.svg" alt="logo" />
          <div className="header">The Miracle of Life Study</div>
          <div className="sub-header">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </div>
          <div className="desc">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
          </div>
          <div>
            <Button
              onClick={eligibleHandler}
              style={{
                height: "55px",
                borderRadius: "6px",
                width: "400px",
              }}
              type="primary"
            >
              <span className="fw-semibold font-sm">Are you eligible</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
