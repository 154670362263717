import { Modal, Button, Select, RadioChangeEvent, Radio } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import React, { useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";

const { Option } = Select;

type Props = {
  show: boolean;
  selectedQuestionniare: any;
  questionId: string;
  userId: string;
  fetchQueries: Function;
  title: string;
};

const ChangeConfigModal = (props: Props) => {
  const dispatch = useAppDispatch();
  // const [remark, setRemark] = useState("");
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => setValue(e.target.value);

  // const changeHandler = (event: any) => {
  //   setRemark(event.target.value);
  // };

  const submitHandler = async () => {
    try {
      // if (!remark.trim()) {
      //   toastMessage("warning", "Remark cannot be empty");
      //   return;
      // }
      // dispatch(setAppLoader(true));
      // const body = {
      //   phaseId: props.selectedQuestionniare?.phaseId,
      //   questionnaireId: props.selectedQuestionniare?.id,
      //   questionId: props.questionId,
      //   userId: props.userId,
      //   remark: remark,
      // };
      // let res = await http.post(`/queries`, body);
      // toastMessage("success", res.data.message);
      // closeHandler();
      // props.fetchQueries();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };
  return (
    <Modal
      title={"Screening ID Configuration"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-3 text-capitalize">
              Set up automatic Econsent screening ID sequence?
            </div>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Radio.Group>
          </div>
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-3 text-capitalize">
              Would you like to have a site abbreviation included?
            </div>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>Yes</Radio>
              <Radio value={2}>No</Radio>
            </Radio.Group>
          </div>
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-3 text-capitalize">
              Would you like to add a “-” or “_” as separator between the site
              abbreviation and increment?
            </div>
            <Select
              size={"large"}
              className="sd__modal-select"
              placeholder="Select..."
              onChange={() => {}}
            >
              <Option value="xyz@gmail.com">xyz@gmail.com</Option>
            </Select>
          </div>
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-3 text-capitalize">
              Number of digits
            </div>
            <Select
              size={"large"}
              className="sd__modal-select"
              placeholder="Select..."
              value={4}
              onChange={() => {}}
            >
              {[1, 2, 3, 4].map((x, i) => (
                <Option value={1} key={i}>
                  {x}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>{" "}
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Set Screening ID</span>
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeConfigModal;
