import { Navigate, Route, Routes } from "react-router-dom";
import { UnAuthenticatedRoute } from "../../components/Common/RouteComponents";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import SetPassword from "./SetPassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <UnAuthenticatedRoute>
            <Login />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <UnAuthenticatedRoute>
            <ForgotPassword />
          </UnAuthenticatedRoute>
        }
      />
      <Route
        path="/set-password/:token"
        element={
          <UnAuthenticatedRoute>
            <SetPassword />
          </UnAuthenticatedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/auth/login" />} />
      <Route path="/" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
