import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

type Props = {
  children?: JSX.Element | JSX.Element[];
  //Pass any array of roles that have access to the route
  disallowedRoles?: string[];
  allowedRoles?: string[];
};

export const AuthenticatedRoute: React.FC<Props> = ({
  children,
  allowedRoles,
  disallowedRoles,
}) => {
  const { isUserAuthenticated, role } = useAppSelector((state) => state.user);

  if (!isUserAuthenticated) {
    return <Navigate to="/auth/login" />;
  }

  if (disallowedRoles && disallowedRoles.includes(role)) {
    const url = role === "patient" ? "/participant/dashboard" : "/my-studies";
    return <Navigate to={url} />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    const url = role === "patient" ? "/participant/dashboard" : "/my-studies";
    return <Navigate to={url} />;
  }

  return <>{children}</>;
};

export const UnAuthenticatedRoute: React.FC = ({ children }) => {
  const { isUserAuthenticated, role } = useAppSelector((state) => state.user);

  const url = role === "patient" ? "/participant/dashboard" : "/my-studies";

  return isUserAuthenticated ? <Navigate to={url} /> : <>{children}</>;
};
