import { Formik } from "formik";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Input, Button, DatePicker, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import moment from "moment";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().required("Form Name is Required"),
});

type Props = {
  show: boolean;
  modalType: string;
  questionData?: any;
  readType?: any;
  refreshPage: Function;
};

const AddConsent = (props: Props) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      // dispatch(setAppLoader(true));
      // let data = {
      //   ...values,
      //   studyId: getStudyId(),
      // };
      // if (data.name.trim() === "") {
      //   throw new Error(`No white spaces are allowed in Questionnaire name`);
      // }
      // if (data.imageUrl === "") {
      //   delete data.imageUrl;
      // }
      // if (props.questionData) {
      //   data["status"] = props.questionData.status;
      //   if (data.description === "") {
      //     data.description = ".";
      //   }
      //   const res = await http.put(
      //     `/questionnaires/${props.questionData.id}`,
      //     data
      //   );
      //   dispatch(hideModal());
      //   dispatch(setAppLoader(false));
      //   toastMessage("success", res.data.message);
      //   props.refreshPage();
      // } else {
      //   const res = await http.post("/questionnaires", data);
      //   dispatch(hideModal());
      //   dispatch(setAppLoader(false));
      //   toastMessage("success", res.data.message);
      //   props.refreshPage();
      // }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={"Create New Consent Form"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            name: props.questionData?.name || "",
            country: props.questionData?.country || [],
            site: props.questionData?.site || [],
            language: props.questionData?.language || "en",
            version: props.questionData?.version || "",
            versionDate: props.questionData?.versionDate || null,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row add-education-wrapper">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      FORM NAME
                    </div>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    COUNTRY
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={values.country}
                    onChange={(val) => {
                      setFieldValue(`country`, val);
                    }}
                  >
                    <Select.Option value="United States" key="United States">
                      United States
                    </Select.Option>
                    <Select.Option value="United Kingdom" key="United Kingdom">
                      United Kingdom
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.country && touched.country && errors.country}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    SITE(S)
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    mode="multiple"
                    style={{ width: "100%" }}
                    value={values.site}
                    onChange={(val) => {
                      setFieldValue(`site`, val);
                    }}
                  >
                    <Select.Option value="test1" key="test1">
                      Test site 1
                    </Select.Option>
                    <Select.Option value="test2" key="test2">
                      Test site 2
                    </Select.Option>
                    <Select.Option value="test3" key="test3">
                      Test site 3
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.site && touched.site && errors.site}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    LANGUAGE
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.language}
                    onChange={(val) => {
                      setFieldValue(`language`, val);
                    }}
                  >
                    <Select.Option value="en" key="en">
                      English
                    </Select.Option>
                    <Select.Option value="es" key="es">
                      Spanish
                    </Select.Option>
                    <Select.Option value="fr" key="fr">
                      French
                    </Select.Option>
                    <Select.Option value="it" key="it">
                      Italian
                    </Select.Option>
                    <Select.Option value="ind" key="ind">
                      Indonesian
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.language && touched.language && errors.language}
                  </div>
                </div>
                <div className="d-flex mb-5">
                  <div
                    style={{ flex: 1, minWidth: "0px", marginRight: "20px" }}
                  >
                    <div className="text-secondary font-m fw-medium mb-1">
                      VERSION NUMBER
                    </div>
                    <Input
                      name="version"
                      value={values.version}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {errors.version && touched.version && errors.version}
                    </div>
                  </div>
                  <div style={{ flex: 1, minWidth: "0px" }}>
                    <div className="text-secondary font-m fw-medium mb-1">
                      VERSION DATE
                    </div>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        values.versionDate
                          ? moment(values.versionDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "6px",
                        height: "44px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue(`versionDate`, dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.versionDate &&
                        touched.versionDate &&
                        errors.versionDate}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">Create New Form</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddConsent;
