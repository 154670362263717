import { Modal, Input, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { ContainerOutlined } from "@ant-design/icons";

const { Option } = Select;

type Props = {
  show: boolean;
  selectedQuestionniare: any;
  questionId: string;
  userId: string;
  fetchQueries: Function;
  title: string;
};

const StudyDetailsModal = (props: Props) => {
  const dispatch = useAppDispatch();
  // const [remark, setRemark] = useState("");

  // const changeHandler = (event: any) => {
  //   setRemark(event.target.value);
  // };

  const submitHandler = async () => {
    try {
      // if (!remark.trim()) {
      //   toastMessage("warning", "Remark cannot be empty");
      //   return;
      // }
      // dispatch(setAppLoader(true));
      // const body = {
      //   phaseId: props.selectedQuestionniare?.phaseId,
      //   questionnaireId: props.selectedQuestionniare?.id,
      //   questionId: props.questionId,
      //   userId: props.userId,
      //   remark: remark,
      // };
      // let res = await http.post(`/queries`, body);
      // toastMessage("success", res.data.message);
      // closeHandler();
      // props.fetchQueries();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };
  return (
    <Modal
      title={"Edit Overseeing Organization"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-1 text-uppercase">
              organization name
            </div>
            <Input
              name="name"
              className="singer-input"
              style={{ resize: "none" }}
              defaultValue="Main Institute"
              // value={remark}
              suffix={<ContainerOutlined />}
              // onChange={changeHandler}
            />
          </div>
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-1 text-uppercase">
              abbreviation
            </div>
            <Input
              name="name"
              className="singer-input"
              style={{ resize: "none" }}
              defaultValue="MNI"
              // value={remark}
              // onChange={changeHandler}
            />
          </div>
          <div className="form-group mb-5">
            <div className="text-secondary font-m fw-medium mb-1 text-uppercase">
              country
            </div>
            <Select
              size={"large"}
              className="sd__modal-select"
              placeholder="Select.."
              defaultValue={"US"}
              // onChange={() => {}}
            >
              <Option value="US">United States</Option>
              <Option value="CAN">Canada</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default StudyDetailsModal;
