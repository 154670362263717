import { Formik } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Upload, Input, Button, DatePicker } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { UploadImage } from "../../../helpers/imageUpload";
// import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { getStudyId } from "../../../helpers/study";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import http from "../../../http";
import { useNavigate } from "react-router-dom";

let yup = require("yup");

let schema = yup.object().shape({
  name: yup.string().required("Questionnaire Name is Required"),
  description: yup.string().required("Questionnaire description is Required"),
});

type Props = {
  show: boolean;
  modalType: string;
  questionData?: any;
  readType?: any;
  refreshPage: Function;
};

const AddQuestions = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", "Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleAvater = (fileObj: any, setFieldValue: Function) => {
    const action = (data: any) => {
      setFieldValue("imageUrl", data[0]?.postUploadImageUrl);
    };
    dispatch(UploadImage(fileObj, action, "questionnaire_image"));
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      let data: any = {
        name: values.name,
        description: values.description,
        category: 1,
        studyId: getStudyId(),
        imageUrl: values.imageUrl ? values.imageUrl : undefined,
      };
      if (data.name.trim() === "") {
        throw new Error(`No white spaces are allowed in Questionnaire name`);
      }
      if (props.questionData) {
        data["status"] = props.questionData.status;
        const res = await http.put(
          `/questionnaires/${props.questionData.id}`,
          data
        );
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        props.refreshPage();
      } else {
        const res = await http.post("/questionnaires", data);
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        navigate(
          `/study/question/build?id=${res.data.data.id}&name=${res.data.data.name}`
        );
      }
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={
        props.questionData
          ? "Edit Screening Questionnaire"
          : "Create Screening Questionnaire"
      }
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            name: props.questionData?.name || "",
            description: props.questionData?.description || "",
            imageUrl: props.questionData?.imageUrl || "",
            startDate: props.questionData?.startDate || null,
            endDate: props.questionData?.endDate || null,
            // category: props.questionData?.category || 1,
            // isEligibilty: props.questionData?.isEligibilty || false,
            // isChinese: props.questionData?.isChinese || false,
            // allowInvestigator: props.questionData?.allowInvestigator || false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row add-education-wrapper">
                <div className="col-md-8 image-row mb-5">
                  <div className="text-secondary font-m fw-medium mb-4">
                    IMAGE
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <Upload
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={(req) =>
                          handleAvater(req, setFieldValue)
                        }
                        accept=".gif,.jpg,.jpeg,.png"
                        className="econsent-custom-upload"
                      >
                        {values.imageUrl ? (
                          <img
                            src={values.imageUrl}
                            alt="question"
                            className="upload-img"
                          />
                        ) : (
                          <div className="placeholder">
                            <div>
                              <PlusOutlined />
                            </div>
                          </div>
                        )}
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      QUESTIONNAIRE TITLE
                    </div>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {errors.name && touched.name && errors.name}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      QUESTIONNIARE DESCRIPTION
                    </div>
                    <Input.TextArea
                      rows={3}
                      maxLength={300}
                      showCount
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="input-error">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </div>
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="isEligibilty"
                      checked={values.isEligibilty}
                      onChange={(e) => {
                        setFieldValue("isEligibilty", e.target.checked);
                      }}
                    >
                      Screening Eligibility CRF
                    </Checkbox>
                  </div>
                </div> */}
                {/* <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="isChinese"
                      checked={values.isChinese}
                      onChange={(e) => {
                        setFieldValue("isChinese", e.target.checked);
                      }}
                    >
                      Allow chinese labels
                    </Checkbox>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <Checkbox
                      name="allowInvestigator"
                      checked={values.allowInvestigator}
                      onChange={(e) => {
                        setFieldValue("allowInvestigator", e.target.checked);
                      }}
                    >
                      Data entry only by Investigator
                    </Checkbox>
                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Type
                    </div>
                    <Select
                      className="custom-select-field"
                      size="large"
                      value={values.category}
                      style={{ width: "100%" }}
                      onChange={(val) => {
                        setFieldValue("category", val);
                      }}
                    >
                      <Select.Option value={1}>e-CRF</Select.Option>
                      <Select.Option value={2}>e-PRO</Select.Option>
                    </Select>
                  </div>
                </div> */}
                <div className="d-flex mb-5">
                  <div
                    style={{ flex: 1, minWidth: "0px", marginRight: "20px" }}
                  >
                    <div className="text-secondary font-m fw-medium mb-1">
                      START DATE
                    </div>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        values.startDate
                          ? moment(values.startDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "6px",
                        height: "44px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue(`startDate`, dateString);
                      }}
                      inputReadOnly
                    />
                  </div>
                  <div style={{ flex: 1, minWidth: "0px" }}>
                    <div className="text-secondary font-m fw-medium mb-1">
                      END DATE
                    </div>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        values.endDate
                          ? moment(values.endDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "6px",
                        height: "44px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue(`endDate`, dateString);
                      }}
                      inputReadOnly
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">
                      {props.questionData ? "Update" : "Save & Add Questions"}
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddQuestions;
