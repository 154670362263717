import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface eligibilityState {
  step: number;
}

const initialState: eligibilityState = {
  step: 1,
};

export const eligibilitySlice = createSlice({
  name: "eligibility",
  initialState,
  reducers: {
    setEligibilityStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const { setEligibilityStep } = eligibilitySlice.actions;

export default eligibilitySlice.reducer;
