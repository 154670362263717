import React from "react";
import { useAppSelector } from "../../redux/hooks";
import AddConsent from "./AddConsentModal/AddConsentModal";

import AddQuestionModal from "./AddQuestionModal/AddQuestionModal";
import AddSiteModal from "./StudyDetailsModal/AddSiteModal";
import EditSiteModal from "./StudyDetailsModal/EditSiteModal";
import StudyDetailsModal from "./StudyDetailsModal/StudyDetailsModal";
import ChangeConfigModal from "./UpdateStudyModal/ChangeConfigModal";
import AddUserModal from "./UserAndRolesModal/AddUserModal";
import ManageModal from "./UserAndRolesModal/ManageModal";

const MODAL_COMPONENTS: any = {
  ADD_QUESTIONS: AddQuestionModal,
  EDIT_QUESTIONS: AddQuestionModal,
  ADD_CONSENT: AddConsent,
  MANAGE_STUDY_DETAILS: StudyDetailsModal,
  ADD_SITE: AddSiteModal,
  EDIT_SITE: EditSiteModal,
  ADD_USER: AddUserModal,
  MANAGE_USER: ManageModal,
  CHANGE_CONFIG: ChangeConfigModal,
};

const RootModal = () => {
  const { type, modalProps } = useAppSelector((state) => state.modal);
  if (!type) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[type];
  if (SpecificModal) {
    return <SpecificModal {...modalProps} />;
  } else {
    return null;
  }
};

export default RootModal;
