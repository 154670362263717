import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { AxiosResponse } from "axios";
import http from "../../http";

let schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("New Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const SetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      let data = {
        password: values.password,
        token: token,
      };
      const res: AxiosResponse = await http.put(`/auth/set_password`, data);
      toastMessage("success", res.data.message);
      dispatch(setAppLoader(false));
      navigate("/auth/login");
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <div className="auth-forgot">
      <div className="forgot-card">
        <div className="forgot-header">Set new password</div>
        <div className="forgot-sub-header mb-5">
          Your new password must be different from previous used passwords.
        </div>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-5">
                <div className="text-gray font-sm mb-3">New Password</div>
                <Input.Password
                  name="password"
                  placeholder="New Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="singer-input"
                />
                <div className="input-error">
                  {errors.password && touched.password && errors.password}
                </div>
              </div>
              <div>
                <div className="text-gray font-sm mb-3">Confirm Password</div>
                <Input.Password
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="singer-input"
                />
                <div className="input-error">
                  {errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword}
                </div>
              </div>
              <div className="mt-4">
                <Button
                  htmlType="submit"
                  style={{
                    height: "50px",
                    width: "100%",
                    borderRadius: "6px",
                  }}
                  type="primary"
                >
                  <span className="fw-semibold font-sm">Set Password</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SetPassword;
