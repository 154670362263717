import { LeftOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
});

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const cancel = () => {
    navigate("/auth/login");
  };

  const submit = async () => {
    try {
      const data = {
        email,
      };
      const valid = await schema.validate(data);
      if (!valid) {
        toastMessage("warning", "Enter a valid email");
        return;
      }
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.post("/auth/forgot_password", data);
      toastMessage("success", res.data.message);
      dispatch(setAppLoader(false));
      setSent(true);
    } catch (err) {
      errorToastMessage(err);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <div className="auth-forgot">
      <div onClick={cancel} className="back-header">
        <LeftOutlined />
        <span className="ms-1">Back</span>
      </div>
      {sent ? (
        <div className="forgot-card sent">
          <div className="forgot-header">Check your email</div>
          <div className="forgot-sub-header mb-3">
            {"We sent a password reset link to " + email}
          </div>
          <div className="resend mt-5">
            Didn’t receive the email?{" "}
            <span className="resend-link" onClick={submit}>
              Click to resend
            </span>
          </div>
        </div>
      ) : (
        <div className="forgot-card">
          <div className="forgot-header">Forgot your Password</div>
          <div className="forgot-sub-header mb-3">
            Enter your email address, and we’ll send you an email with all the
            instructions.
          </div>
          <div className="mt-5">
            <div className="text-gray font-sm mb-3">Email</div>
            <Input
              name="email"
              placeholder="Your Email Address"
              value={email}
              onChange={handleChange}
              className="singer-input"
            />
          </div>
          <div className="mt-4">
            <Button
              style={{
                height: "50px",
                width: "100%",
                borderRadius: "6px",
              }}
              type="primary"
              onClick={submit}
            >
              <span className="fw-semibold font-sm">Send me instructions</span>
            </Button>
          </div>
          <div className="mt-2">
            <Button
              style={{
                height: "50px",
                width: "100%",
              }}
              type="ghost"
              onClick={cancel}
            >
              <span className="fw-semibold font-sm">Cancel</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
