import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Input } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { NavLink } from "react-router-dom";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import { AxiosResponse } from "axios";
import {
  setAuthenticated,
  setRole,
  setUserName,
} from "../../redux/reducers/userSlice";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid Email ID.")
    .required("Email ID is Required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Password is Required"),
});

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const loginHandler = async (form: any) => {
    try {
      dispatch(setAppLoader(true));
      const res: AxiosResponse = await http.post("/auth/login", form);
      const response = res.data.data;
      const name = `${response.firstName} ${response.lastName}`;
      const { id, token, role } = response;
      localStorage.setItem("singer-accessToken", token);
      localStorage.setItem("userId", id);
      localStorage.setItem("role", role);
      localStorage.setItem("fullName", name);
      dispatch(setUserName(name));
      dispatch(setRole(role));
      dispatch(setAuthenticated(true));
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <div className="auth-login">
      <img src="/LargeLogo.svg" alt="logo" className="logo" />
      <div className="consent-auth-left-block"></div>
      <div className="consent-auth-right-block">
        <div className="login-header">Log In</div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={schema}
          onSubmit={(values: any) => {
            loginHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="text-gray font-sm mb-3">Email</div>
                  <Input
                    name="email"
                    placeholder="Your Email Address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left">
                    {touched.email && errors.email}
                  </div>
                </div>
                <div>
                  <div className="text-gray font-sm mb-3">Password</div>
                  <Input.Password
                    name="password"
                    placeholder="Your password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left">
                    {touched.password && errors.password}
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    htmlType="submit"
                    style={{
                      height: "50px",
                      width: "100%",
                      borderRadius: "6px",
                    }}
                    type="primary"
                  >
                    <span className="fw-semibold font-sm">Login</span>
                  </Button>
                </div>
                <div className="terms mt-2">
                  By signing in you agree to Econsent{" "}
                  <span className="links">Terms of service</span> and
                  <span className="links">{" Privacy policy"}</span>.
                </div>
                <div className="mt-3 forgot-password">
                  <NavLink to="/auth/forgot-password">
                    Forgot your password?
                  </NavLink>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
